import React from 'react'
import { Link } from "gatsby";
import rewriteSlug from '../../../utils/rewriteSlug'
import { render } from "storyblok-rich-text-react-renderer"
import { sbEditable } from "@storyblok/storyblok-editable";


const HowitWorks = ({ blok, lang }) => {
    const serviceBloks = blok.htw_steplist.map((steplist, i) =>
        <div className="tile is-parent is-vertical mx-6 mb-6" key={i}>
            <article className="tile is-child bg-light-beige">
                <img
                    className="service-image"
                    src={steplist.image.filename}
                    alt={steplist.image.alt}
                />
            </article>
            <article className="tile is-child box bg-blue">
                <h3 className="title is-5">{steplist.description}</h3>
                <h3 className="title is-4">{steplist.hotline_number}</h3>
            </article>
        </div>

    )

    return (
        <div {...sbEditable(blok)}>
            <section className="section is-medium bg-light-beige" style={{ paddingTop: "2rem" }}>
                <div className="container has-text-centered">
                    <h1 className="is-size-2 label">{blok.title}</h1>
                    <div className="tile is-ancestor">
                        <div className="tile is-vertical">
                            <div className="tile">
                                {serviceBloks}
                            </div>
                        </div>
                    </div>
                    <div className="columns">
                        <div className="column is-two-thirds">
                            <p className="label is-size-4">
                                {blok.action_description}
                            </p>

                        </div>
                        <div className="column is-one-third has-text-centered">
                            <p>
                                <Link
                                    to={`/${rewriteSlug(blok.button_link.cached_url, lang)}`}
                                    className="button site-button is-rounded is-large mb-6"
                                >
                                    {blok.button_name}
                                </Link>
                            </p>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default HowitWorks;