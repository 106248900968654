import React from 'react'
import { Link } from "gatsby";
import { render } from "storyblok-rich-text-react-renderer"
import { sbEditable } from "@storyblok/storyblok-editable";


const About = ({ blok }) => {
    // console.log(blok)

    const createColumns = (list) => list.map((item, i) =>
        <div className="column is-one-third px-6 has-text-centered py-6" key={i}>
            <a
                href={item.website.cached_url}
                target="_blank"
                className="has-text-centered"
            >
                <img
                    style={{
                        maxHeight: "100px",
                        width: "auto",
                        height: "100px"
                    }}
                    src={item.logo.filename}
                    alt={item.logo.alt}
                />
                <p className="is-size-6">{item.name}</p>
            </a>
        </div>
    )

    return (
        <div {...sbEditable(blok)}>
            <section id="services" className="section is-medium bg-light-beige">
                <div className="container">
                    <div className="columns is-vcentered">
                        <div className="column is-two-thirds">
                            <div className="content">
                                <h1 className="is-size-2 label">{blok.title}</h1>
                                {render(blok.description)}
                            </div>
                        </div>
                        <div className="column is-one-third has-text-centered-mobile">
                            <img
                                className="service-image"
                                src={blok.about_image.filename}
                                alt={blok.about_image.alt}
                            />
                        </div>
                    </div>
                    <div className="card">
                        <div className="card-content">
                            <h1 className="is-size-4 label">{blok.enrollers}</h1>
                            <div className="columns is-multiline">
                                {createColumns(blok.enrollers_list)}
                            </div>
                        </div>
                    </div>
                    <div className="card mt-4">
                        <div className="card-content">
                            <h1 className="is-size-4 label">{blok.com_ed_title}</h1>
                            <div className="columns is-multiline">
                                {createColumns(blok.com_ed_outreach)}
                            </div>
                        </div>
                    </div>
                    <div className="card mt-4">
                        <div className="card-content">
                            <h1 className="is-size-4 label">{blok.legal_service_providers}</h1>
                            <div className="columns is-multiline">
                                {createColumns(blok.legal_service_list)}
                            </div>
                        </div>
                    </div>
                    <div className="card mt-4">
                        <div className="card-content">
                            <h1 className="is-size-4 label">{blok.funder_title}</h1>
                            <div className="columns is-multiline">
                                {createColumns(blok.funder)}
                            </div>
                        </div>
                    </div>

                </div>
            </section>
        </div>
    )
}

export default About;