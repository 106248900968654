import React from 'react'
import { Link } from "gatsby";
import { render } from "storyblok-rich-text-react-renderer"
import { sbEditable } from "@storyblok/storyblok-editable";


const ResourcesList = ({ blok }) => {
    console.log(blok)

    const EventList = blok.resource.map((resource, i) => {
        return <div className="card mb-5" key={i} style={{ backgroundColor: "#FFEBD0" }}>
            <div className="card-content">
            <div className="column is-11">
                        <div className="content">
                            <p className="has-text-weight-bold is-size-5">{resource.title}</p>
                            <p>
                                {render(resource.description)}
                            </p>
                            <a href={resource.link.url}>Link</a>
                        </div>
                    </div>
            </div>
        </div>
    }
    )

    return (
        <div {...sbEditable(blok)}>
            <div className="section" style={{ backgroundColor: "#FFF" }}>
                <div className="container">
                    <h1
                        className="is-size-3 label has-text-centered"
                    >
                        {blok.title}
                    </h1>
                    {EventList}
                </div>

            </div>
        </div>
    )
}

export default ResourcesList;