import React, { useState, useEffect } from "react";
import { Link } from "gatsby"
import rewriteSlug from "../utils/rewriteSlug";
import { sbEditable } from "@storyblok/storyblok-editable";
import { navigate } from "gatsby"
import { moranIndex } from "@turf/turf";

const Navigation = ({ settings, lang }) => {
    // console.log(settings)
    // console.log(lang)

    const [langState, setLangState] = useState(lang)

    const langlist = [
        { lang: "", name: "ENGLISH" },
        { lang: "es", name: "ESPAÑOL" },
        { lang: "zh", name: "中文" },
        { lang: "vi", name: "TIẾNG VIỆT" },
        { lang: "ko", name: "한국어" },
        { lang: "km", name: "ខ្មែរ" },
        { lang: "hy", name: "հայերեն" },
        { lang: "ru", name: "русский" },
        { lang: "tl", name: "TAGALOG" },
        { lang: "th", name: "ไทย" },
        { lang: "hi", name: "हिन्दी" },
        { lang: "pa", name: "ਪੰਜਾਬੀ" },
        { lang: "ar", name: "اردو" },
        { lang: "fa", name: "فارسی" },
        { lang: "ur", name: "عربي" }
    ]

    const setLanguageSelect = (lang) => {
        const selectedLang = lang.target.value
        const pagePath = window.location.pathname
        // console.log(selectedLang)
        // console.log(pagePath)
        // console.log(rewriteSlug(pagePath, selectedLang))
        // console.log(pagePath.substring(3, 4))
        if (pagePath.substring(3, 4) == "/" && selectedLang != "") {
            // console.log("/" + selectedLang + pagePath.substring(3))
            navigate("/" + selectedLang + pagePath.substring(3))
        } else if (selectedLang != "") {
            // console.log("/" + selectedLang + pagePath)
            navigate("/" + selectedLang + pagePath)
        }

        if (selectedLang == "") {
            navigate(selectedLang + pagePath.substring(3))
        }

    }

    // var language = ""
    // lang === "default" ?
    //     language = '.' :
    //     language = lang

    // console.log(language)

    const [isTransparent, setTransparent] = useState("00")

    const listenScrollEvent = (event) => {
        if (window.scrollY < 100) {
            setTransparent("00")
        } else if (window.scrollY > 70) {
            setTransparent("FF")
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);
    }, [])

    function toggleBurgerMenu() {
        document.querySelector(".navbar-menu").classList.toggle("is-active");
        document.querySelector(".navbar-burger").classList.toggle("is-active");
    }

    const menuItems = settings.Menu.map((menuItem, index) =>
        <React.Fragment key={index}>
            {menuItem.AddToNavbar ?
                <Link
                    to={`/${rewriteSlug(menuItem.Link.cached_url, lang)}`}
                    // to={`/${language}${menuItem.Link.cached_url}`}"
                    prefetch="true"
                    className="navbar-item"
                >
                    {menuItem.Item}
                </Link>
                : <></>
            }
        </React.Fragment>
    )

    //This needs to be updated with the "a" tag, replace div
    const navbarItems = settings.Menu.map((menuItem, index) =>
        <React.Fragment key={index}>
            {menuItem.AddToNavbar ?
                <Link
                    className="navbar-item"
                    to={`/${rewriteSlug(menuItem.Link.cached_url, lang)}`}
                    // to={`/${language}${menuItem.Link.cached_url}`} 
                    prefetch="true"
                >
                    {menuItem.Item}
                </Link>
                : <></>
            }
        </React.Fragment>
    )


    return (

        <nav
            className="navbar is-fixed-top"
            role="navigation"
            aria-label="main navigation"
            style={{ backgroundColor: `#B6DBD7` }}
            {...sbEditable(settings)}
        >
            <div className="navbar-brand">
                <Link
                    className="navbar-item font-family-titan has-text-black"
                    to={`/${rewriteSlug(lang)}`} prefetch="true"
                >
                    BAILA NETWORK
                </Link>
                <div className="navbar-item is-hidden-desktop">
                    <div className="select" >
                        <select
                            onChange={e => setLanguageSelect(e)}
                            defaultValue={langState}
                            style={{ backgroundColor: "#ffe08a" }}
                        >
                            {langlist.map((lang, key) =>
                                <option value={lang.lang}>{lang.name}</option>
                            )}
                        </select>
                    </div>
                </div>

                <button className="navbar-burger" aria-label="menu" aria-expanded="false" onClick={toggleBurgerMenu}>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </button>
            </div>

            <div className="navbar-start is-hidden-touch">
                {navbarItems}
            </div>

            <div className="navbar-menu navtitle">

                <div className="navbar-end">
                    <div className="navbar-item is-hidden-desktop">
                        <div className="select" >
                            <select
                                onChange={e => setLanguageSelect(e)}
                                defaultValue={langState}
                                style={{ backgroundColor: "#ffe08a" }}
                            >
                                {langlist.map((lang, key) =>
                                    <option value={lang.lang}>{lang.name}</option>
                                )}
                            </select>
                        </div>
                    </div>
                    <div className="navbar-item has-dropdown is-hoverable">
                        <div className="navbar-link">Menu</div>
                        <div className="navbar-dropdown is-right">
                            {menuItems}
                        </div>
                    </div>
                </div>
            </div>

        </nav>

    )
}

export default Navigation;