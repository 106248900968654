import React from 'react'
// import { Link } from "gatsby";
import { render } from "storyblok-rich-text-react-renderer"
import { sbEditable } from "@storyblok/storyblok-editable";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

// import 'react-accessible-accordion/dist/fancy-example.css';


const FrequentlyAskedQuestions = ({ blok }) => {
    // console.log(blok)

    const getAccordion = blok.faqList.map((faq, i) =>
        <AccordionItem key={i} className="mt-4">
            <AccordionItemHeading>
                <AccordionItemButton
                className="accordion__button notification has-text-white is-size-5"
                style={{backgroundColor:"#30574a"}}
                >
                    {faq.question}
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
                <div className="notification content has-background-white">
                    {render(faq.answer)}
                </div>
            </AccordionItemPanel>
        </AccordionItem>
    )

    return (
        <div {...sbEditable(blok)}>
            <div className="section" style={{ backgroundColor: "#FFF7EC" }}>
                <div className="container">
                    <h1
                        className="is-size-3 label has-text-centered"
                    >
                        {blok.title}
                    </h1>
                    <Accordion>
                        {getAccordion}
                    </Accordion>
                </div>
            </div>
            <div className="section" style={{ backgroundColor: "#FFF7EC" }}></div>
            <div className="section" style={{ backgroundColor: "#FFF7EC" }}></div>
        </div>
    )
}

export default FrequentlyAskedQuestions;