function rewriteSlug(slug, lang) {
    // console.log(lang)
    const defaultLanguage = 'default'
    let newSlug = slug
    // replaces /de/home with /de
    newSlug = newSlug.replace('home', '/')
    // replaces /en/blog/first-post with /blog/first-post
    newSlug = newSlug.replace(defaultLanguage, '')
    if(newSlug.indexOf("/") === 0){
        if (lang =='default') {
            newSlug = newSlug.replace("/", '')
        } else {
            newSlug = newSlug.replace("/", `${lang}/`)
        }
    }
    return newSlug
}

// es5 export because of import into gatsby-node.js
module.exports = rewriteSlug