import React from "react"
import { Helmet } from "react-helmet"

export default (rtl) => {
    // console.log(rtl)
    return <Helmet>
        <meta
            name="viewport"
            content="width=device-width, initial-scale=1, maximum-scale=1, user-scalable=0"
        />
        <title>BAILA Network</title>
        <link rel="icon" type="image/x-icon" href="/favicon2.ico"></link>
        <meta name="description" content='Benefits Access for Immigrants Los Angeles' />
        <meta name="keywords" content="" />
        <html dir={rtl.lang}/>
    </Helmet>
}