import React from 'react'
import { Link } from "gatsby";
import { render } from "storyblok-rich-text-react-renderer"
import rewriteSlug from '../utils/rewriteSlug'
import { sbEditable } from "@storyblok/storyblok-editable";


const Section = ({ blok, lang }) => {
    // console.log(blok)

    const button = <>
        {blok.button_name ?
            <Link
                to={`/${rewriteSlug(blok.button_link.cached_url, lang)}`}
                className="button site-button is-rounded is-large mb-6"
            >
                {blok.button_name}
            </Link>
            :
            <></>
        }
    </>

    return (
        <div {...sbEditable(blok)}>
            <div className={`section ${blok.color}`}>
                <div className="container">
                    <div className="columns is-vcentered">
                        {
                            blok.layout === 'image-left' ?
                                <>
                                    <div className="column is-one-third has-text-centered-mobile">
                                        <img
                                            className="service-image"
                                            src={blok.image.filename}
                                            alt={blok.image.alt}
                                        />
                                    </div>
                                    <div className="column is-two-thirds has-text-black has-text-left is-size-5">
                                        <p className="label is-size-3">{blok.title}</p>
                                        <div className="content">
                                            {render(blok.description)}
                                        </div>
                                        {button}
                                    </div>
                                </> :
                                <>
                                    <div className="column is-two-thirds has-text-black has-text-left is-size-5 br-third-biege">
                                        <p className="label is-size-3">{blok.title}</p>
                                        <div className="content">
                                            {render(blok.description)}
                                        </div>
                                        {button}
                                    </div>
                                    <div className="column is-one-third has-text-centered-mobile">
                                        <img
                                            className="service-image"
                                            src={blok.image.filename}
                                            alt={blok.image.alt}
                                        />
                                    </div>
                                </>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Section;