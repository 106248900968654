import React from 'react'
import { Link } from "gatsby";
import { render } from "storyblok-rich-text-react-renderer"
import { sbEditable } from "@storyblok/storyblok-editable";


const ResourceHeading = ({ blok }) => {
    // console.log(blok)

    // const createColumns = (list) => list.map((item, i) =>
    //     <div className="column is-one-third px-6 has-text-centered py-6" key={i}>
    //         <a
    //             href={item.website.cached_url}
    //             target="_blank"
    //             className="has-text-centered"
    //         >
    //             <img
    //                 style={{
    //                     maxHeight: "100px",
    //                     width: "auto",
    //                     height: "100px"
    //                 }}
    //                 src={item.logo.filename}
    //                 alt={item.logo.alt}
    //             />
    //             <p className="is-size-6">{item.name}</p>
    //         </a>
    //     </div>
    // )

    return (
        <div {...sbEditable(blok)}>
            <section id="services" className="section is-medium bg-light-beige">
                <div className="container">
                    <div className="columns is-vcentered">
                        <div className="column is-two-thirds py-0">
                            <div className="content">
                                <h1 className="is-size-2 label resourcesTitle">{blok.title}</h1>
                                {render(blok.description)}
                            </div>
                        </div>
                        <div className="column is-one-third has-text-centered-mobile  py-0">
                            <img
                                className="service-image"
                                src={blok.image.filename}
                                alt={blok.image.alt}
                            />
                        </div>
                    </div>
                </div>
            </section>
        </div>
    )
}

export default ResourceHeading;