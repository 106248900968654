import React from 'react'
import { Link } from "gatsby";
import rewriteSlug from '../../../utils/rewriteSlug'
import { render } from "storyblok-rich-text-react-renderer"
import { sbEditable } from "@storyblok/storyblok-editable";


const FactsToKnow = ({ blok, lang }) => {
    console.log(blok)
    const url = blok.button.cached_url
    const langURL = url.indexOf("/") === 0 ? `https://www.bailanetwork.org${blok.button.cached_url}` : `https://www.bailanetwork.org/${blok.button.cached_url}`
    console.log(langURL)
    const factsList = blok.fact.map((fact, i) =>
        <div className="card mb-5" key={i} style={{ backgroundColor: "#FFEBD0" }}>
            <div className="card-content">
                <div className="columns">
                    <div
                        className="column is-1"
                    >
                        <p
                            className="notification is-size-2 label has-text-centered"
                            style={{
                                padding: "unset",
                                backgroundColor: "#FBD875",

                            }}
                        >
                            {i + 1}
                        </p>

                    </div>
                    <div className="column is-11 is-size-5">
                        <div className="content">
                            <p>{fact.fact_text}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )

    return (
        <div {...sbEditable(blok)}>
            <div className="section" style={{ backgroundColor: "#FFF7EC" }}>
                <div className="container">
                    <h1
                        className="is-size-3 label has-text-centered"
                    >
                        {blok.title}
                    </h1>
                    {factsList}
                    <div className="notification bg-orange-outline is-size-5 has-text-centered mt-6">
                        <div className="content">
                            {render(blok.additional)}
                        </div>
                        <Link
                            // to={langURL}
                            to={`/${rewriteSlug(blok.button.cached_url, lang)}`}
                            className="button site-button is-rounded is-medium"
                        >
                            {blok.button_name}
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default FactsToKnow;