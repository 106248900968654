import React from 'react'
import { Link } from "gatsby";
import { render } from "storyblok-rich-text-react-renderer"
import { sbEditable } from "@storyblok/storyblok-editable";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';


const EventsList = ({ blok }) => {
    // console.log(blok)

    const EventList = blok.event_post.map((event, i) => {

        const eventDate = new Date(event.date)
        // console.log(eventDate)
        const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric' };

        return <div className="card mb-5" key={i} style={{ backgroundColor: "#FFEBD0" }}>
            <div className="card-content">
                <div className="column is-11">
                    <div className="content">
                        <p className="has-text-weight-bold is-size-4 mb-0">{eventDate.toDateString()}, {event.start_time} - {event.end_time}</p>
                        <p className="has-text-weight-bold is-size-5">{event.title}</p>
                        <p>
                            {render(event.description)}
                            {/* {event.description.content} */}
                        </p>
                        <p>Location: {event.address}</p>
                        <a href={event.link.url} className="button">RSVP</a>
                    </div>
                </div>
            </div>
        </div>
    }
    )

    return (
        <div {...sbEditable(blok)}>
            <div className="section" style={{ backgroundColor: "#FFF" }}>
                {
                    blok.accordion ? <>
                        <div className="container">
                            <h1
                                className="is-size-3 label has-text-centered"
                            >
                                {blok.title}
                            </h1>
                            <p className="is-size-5 has-text-centered">Click the "Past Events/Eventos Pasados" button above for the full list of past events hosted by BAILA.</p>
                            <p className="is-size-5 has-text-centered has-text-weight-bold">See below for slides, flyers, and recordings!</p>
                            <Accordion
                                allowZeroExpanded
                            >
                                <AccordionItem className="mt-4">
                                    <AccordionItemHeading>
                                        <AccordionItemButton
                                            className="accordion__button notification is-size-5"
                                            style={{
                                                backgroundColor: "#FFEBD0"
                                            }}
                                        >
                                            {blok.title}
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <div className="notification content">
                                            {EventList}
                                        </div>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>
                        </div>
                    </> : <>
                        <div className="container">
                            <h1
                                className="is-size-3 label has-text-centered"
                            >
                                {blok.title}
                            </h1>
                            {
                                blok.event_post.length === 0 ? <>
                                    <div className="card mb-5" style={{ backgroundColor: "#FFEBD0" }}>
                                        <div className="card-content">
                                            <div className="column is-11">
                                                <div className="content has-text-centered">
                                                    <p>
                                                        No events at this time
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </> :
                                    EventList
                            }
                        </div>
                    </>
                }
            </div>
        </div>
    )
}

export default EventsList;