import React from 'react'
import { Link } from "gatsby";
import { render } from "storyblok-rich-text-react-renderer"
import { sbEditable } from "@storyblok/storyblok-editable";


const Cover = ({ blok }) => {
    // console.log(blok)

    function scrollTo() {
        const section = "services"
        document.getElementById(section).scrollIntoView({ behavior: 'smooth' })
        document.getElementById(section).focus({ preventScroll: true })
    }

    const bannerHTML = blok.show_banner ?
        <div className="notification is-warning">{render(blok.banner)}</div> :
        <></>


    const coverLanguageLinks = blok.languages.map((lang, index) =>
            <React.Fragment key={index}>
                <Link
                    to={lang.link.cached_url}
                    className="is-underlined is-size-4"
                    style={{ textUnderlineOffset: ".15em" }}
                    key={index}
                >
                    {lang.lang}
                </Link>
                {index != (blok.languages.length - 1) ? " | " : ""}
            </React.Fragment>
        )

    return (
        <div {...sbEditable(blok)}>
            <div className="hero is-fullheight bg-cover">
                <div className="hero-body">
                    <div className="container">
                        {bannerHTML}
                        <div className="columns is-vcentered is-variable is-cover">
                            <div className="column is-half has-text-black is-size-4 has-text-right">
                                <div className="content">
                                    <h1 className="label is-size-2 is-size-3-mobile">
                                        <img src={blok.cover_logo.filename} alt={blok.cover_image.alt} /><br />
                                        {blok.title}
                                    </h1>
                                    {/* <p>{coverLanguageLinks}</p> */}
                                    <p className="is-size-5">{blok.tagline}</p>
                                    <button
                                        className="button site-button is-rounded is-large"
                                        onClick={scrollTo}
                                    >
                                        {blok.button}
                                    </button>
                                </div>
                            </div>
                            <div className="column is-half has-text-black has-text-left is-size-4 is-hidden-mobile">
                                <img src={blok.cover_image.filename} alt={blok.cover_image.alt} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="orange-bar"></div>
            </div>
        </div>
    )
}

export default Cover;