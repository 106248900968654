import React from 'react'
import { Link } from "gatsby";
import rewriteSlug from '../../../utils/rewriteSlug'
import { render } from "storyblok-rich-text-react-renderer"
import { sbEditable } from "@storyblok/storyblok-editable";


const Services = ({ blok, lang }) => {
    // console.log(blok)
    // console.log(lang)
    // console.log(blok.button_link.cached_url)

    const serviceBloks = blok.service.map((service, i) =>
        <div className="tile is-parent is-vertical has-text-centered mx-6 mb-6" key={i}>
            <article className="tile is-child bg-light-beige">
                <img
                    className="service-image"
                    src={service.service_image.filename}
                    alt={service.service_image.alt}
                />
                <h3 className="title is-5 mt-4">{service.description}</h3>
            </article>
        </div>
    )

    return (
        <div {...sbEditable(blok)}>
            <section id="services" className="section is-medium bg-light-beige">
                <div className="container has-text-centered">
                    <div className="tile is-ancestor">
                        <div className="tile is-vertical">
                            <div className="tile">
                                {serviceBloks}
                            </div>
                        </div>
                    </div>
                    <Link
                        // to={blok.button_link.cached_url}
                        to={`/${rewriteSlug(blok.button_link.cached_url, lang)}`}
                        className="button site-button is-rounded is-large mb-6"
                    >
                        {blok.button}
                    </Link>
                    <div className="notification bg-orange-outline is-size-5">
                        {blok.disclaimer}
                    </div>
                </div>
            </section>
        </div>
    )
}

export default Services;