import React from 'react'
// import { Link } from "gatsby";
import { render } from "storyblok-rich-text-react-renderer"
import { sbEditable } from "@storyblok/storyblok-editable";


const SectionTop = ({ blok }) => {
    // console.log(blok)
    return (
        <div {...sbEditable(blok)}>
            <div className="section pb-0" style={{ backgroundColor: "#FFF7EC" }}>
                <div className="container mt-6">
                    <div className="columns is-vcentered">
                        <div className="column is-two-thirds is-size-5">
                            <div className="content">
                                <h1
                                    className="is-size-2 label"
                                    style={{ marginBottom: "5px !important" }}
                                >{blok.title}</h1>
                                <hr
                                    style={{
                                        backgroundColor: "#000",
                                        margin: "0 0 1rem 0"
                                    }}
                                />
                                {render(blok.description)}
                            </div>
                        </div>
                        <div className="column is-one-third has-text-centered-mobile">
                            <img
                                className="service-image"
                                src={blok.image.filename}
                                alt={blok.image.alt}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionTop;