import React, { useState } from "react"
// import { Link } from "gatsby";
import { render } from "storyblok-rich-text-react-renderer"
import { sbEditable } from "@storyblok/storyblok-editable";


const SectionPublicBenefitsTabs = ({ blok }) => {
    console.log(blok)
    const [activeTab, selectTab] = useState(0)

    const getTab = blok.benefits_tab.map((tab, i) =>
        <div
            className="column is-one-quarter-tablet is-half-mobile has-text-centered mb-0 pb-0"
            key={i}
        >
            <div
                className="notification px-2 mb-0 has-text-weight-bold"
                tabkey={i}
                onClick={() => selectTab(i)}
                style={{
                    backgroundColor: i == activeTab ? "#C9E3E0" : "#315748",
                    color: i == activeTab ? "#000" : "#FFF",
                    borderRadius: "8px 8px 8px 8px",
                    borderTopColor: i == activeTab ? "#315748" : "#315748",
                    borderTopWidth: "4px",
                    borderTopStyle: "solid",
                    height: "100%"
                }}
            >
                {tab.title}
            </div>
        </div>
    )

    const getTabInfo = blok.benefits_tab.map((tabinfo, i) =>
        <div
            className="content"
            tabkey={i}
            key={i}
            style={{ display: i == activeTab ? "block" : "none" }}
        >
            <h1
                className="is-size-3 mt-5"
                style={{ marginBottom: "5px !important" }}
            >
                {tabinfo.title}
            </h1>
            <div className="has-text-centered">
                <img
                    // className="service-image"
                    src={tabinfo.image.filename}
                    alt={tabinfo.image.alt}
                />
            </div>

            {tabinfo.info.map((infocontent, i) =>
                <div className="pb-6">
                    <h1
                        className="is-size-6"
                    >{infocontent.title}</h1>
                    <hr
                        style={{
                            backgroundColor: "#000",
                            margin: "0 0 1rem 0",
                            height: "1px"
                        }}
                    />
                    {render(infocontent.description)}
                </div>
            )}
        </div>
    )

    return (
        <div {...sbEditable(blok)}>
            <div className="section" style={{ backgroundColor: "#FFF7EC" }}>
                <div className="columns is-mobile is-multiline">
                    {getTab}
                </div>
                <div className="columns mt-0 pb-0">
                    <div className="column is-full mt-0 pt-0">
                        <div
                            className="notification"
                            style={{
                                backgroundColor: "#C9E3E0",
                                borderRadius: "8px 8px 8px 8px"
                            }}
                        >
                            <div className="container">
                                {getTabInfo}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SectionPublicBenefitsTabs;